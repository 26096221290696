<template>
  <div class="goodsharing MERCH">
    <div class="merch-card scorr-roll">
      <!-- 搜索列 -->
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <!-- 区域选择 -->
          <!-- <div class="sch-1 sch-2">
            <div class="sch-title">所在地区:</div>
            <el-cascader
              v-model="AreaArr"
              :options="AreaList"
              :props="propsValue"
              clearable
              placeholder="省—市—区"
            ></el-cascader>
          </div> -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title exceed">商品名称:</div> -->
            <el-input
              clearable
              placeholder="商品名称/通用名"
              v-model="ycApi.name"
            ></el-input>
          </div>
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title exceed">批准文号:</div> -->
            <el-input
              clearable
              placeholder="批准文号"
              v-model="ycApi.approval_number"
            ></el-input>
          </div>

          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">生产厂家:</div> -->
            <el-input
              clearable
              placeholder="生产厂家"
              v-model="ycApi.manufacturer"
            ></el-input>
          </div>
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">供应商:</div> -->
            <el-select
              clearable
              v-model="ycApi.supplier_id"
              placeholder="请选择供应商"
              filterable
              remote
              reserve-keyword
              :remote-method="remoteMethod"
            >
              <el-option
                v-for="item in businessList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">商品分类:</div> -->
            <el-cascader
              v-model="cascaderValue"
              :options="BasissCopeData.inquiregoods_categoryList"
              @change="cascaderhandleChange"
              :props="propsData"
              :key="refKey"
              placeholder="请选择商品分类"
              clearable
            ></el-cascader>
          </div>

          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">经营类别:</div> -->
            <el-select
              clearable
              v-model="ycApi.scope_id"
              placeholder="请选择商品经营类型"
              filterable
            >
              <el-option
                v-for="item in BasissCopeData.Newbusiness_scope_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">上架状态</div> -->
            <el-select
              clearable
              v-model="ycApi.inventory"
              placeholder="请选择库存状态"
            >
              <el-option
                v-for="item in inventory"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="sch-1 sch-2">
            <div class="sch-title">编号:</div>
            <el-input
              placeholder="请输入编号"
              @change="getList"
              clearable
              v-model="ycApi.id"
            ></el-input>
          </div> -->

          <div class="sch-1 sch-2">
            <el-button type="primary" size="mini" @click="getList">
              查 询
            </el-button>
            <el-button type="primary" size="mini" @click="reset">
              重置查询条件
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table :data="YcData.data" style="width: 100%"  :header-cell-style="{ background: '#F2F3F5'}" border>
            <el-table-column prop="address" label="商品图片" align="center">
              <template slot-scope="{ row }">
                <div class="img-box">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="row.picture"
                      :preview-src-list="[row.picture]"
                    >
                    </el-image>
                  </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="商品信息"
              align="center"
              width="320"
            >
              <template slot-scope="{ row }">
                <div class="goods-message">

                  <div class="span-box">
                    <div class="exceed">商品编号：{{ row.id || "/" }}</div>
                    <div class="exceed">商品名称：{{ row.name || "/" }}</div>
                    <div class="exceed">
                      批准文号：{{ row.approval_number || "/" }}
                    </div>
                    <div class="exceed">
                      生产厂家：{{ row.manufacturer || "/" }}
                    </div>
                    <div class="exceed">
                      规格/包装：{{ row.specification || "/" }}
                    </div>
                    <div class="exceed">
                      <!-- 经营类别：{{ row.type_string || "/" }} -->
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="经营类别" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.type_string"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.type_string || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商品分类" align="center">
              <template slot-scope="{ row }">
                <el-tooltip placement="top">
                  <div slot="content">
                    <span v-for="(v, i) in row.category_string" :key="i"
                      >{{ v }}<br
                    /></span>
                  </div>
                  <div class="exceed">{{ row.category_string[0] || "/" }}</div>
                </el-tooltip>
                <!-- <el-tooltip
                  effect="dark"
                  :content="row.category_string"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.category_string || "/" }}</div>
                </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column prop="address" label="供应商" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.enterprise"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.enterprise || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="销售价" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.selling_price + ''"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.selling_price || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column prop="address" label="商品分佣" align="center">
              <template slot-scope="{ row }">
                <div class="span-box-1">
                  <div class="exceed">
                    销售佣金：{{ row.shared_brokerage || "/" }}
                  </div>
                  <div class="exceed">
                    平台提成：{{ row.platform_shared_brokerage || "/" }}
                  </div>
                  <div class="exceed">
                    销售商分佣 ：{{ row.seller_commission || "/" }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="库存" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.quantity"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.quantity || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商圈" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.dist_string"
                  placement="bottom"
                >
                  <div class="exceed">
                    {{ row.dist_string || "/" }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="170"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <div
                    @click="toMessage(row)"
                    v-if="allJurisdiction.goodsharing139"
                  >
                    详情
                  </div>
                  <div
                    @click="toAdjust(row)"
                    v-if="allJurisdiction.goodsharing140"
                  >
                    调整平台佣金
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="YcData.per_page"
          :current-page="ycApi.page"
          layout="total, prev, pager, next, jumper"
          :total="YcData.total"
        >
        </el-pagination>
      </div>
      <!-- 调整平台提成 -->
      <div>
        <el-dialog title="修改平台分佣比例" :visible.sync="isTc" width="450px">
          <div>
            <el-form ref="form" label-width="120px" >
              <el-form-item label="平台分佣比例：">
                <el-input
                  @input="
                    TcdATA.commission = TcdATA.commission.replace(
                      /^\+|[^\d]/g,
                      ''
                    )
                  "
                  v-model="TcdATA.commission"
                  placeholder="平台分佣比例"
                  ><i slot="suffix" class="el-input__icon">%</i>
                </el-input>
              </el-form-item>
            </el-form>
            <span
              >温馨提示：商品平台提成比例修改时，暂时只能调减无法调增，请悉知并按提示进行修改。</span
            >
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isTc = false">取 消</el-button>
            <el-button type="primary" @click="Adjust">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { categoryList } from "@/utils/index";

const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
//
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      AreaArr: [],
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      }, //级联选择器配置项
      searchData: {},
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      value: "",
      options: [],
      refKey: 0,

      ycApi: {
        business_scope_id: "",
        inventory: "",
        category_id: "",
        keyword: "",
        page: 1,
        whether_shared: 1,
      },
      YcData: {},
      // 库存状态
      inventory: [
        {
          name: "紧缺",
          type: 2,
        },
        {
          name: "充足",
          type: 1,
        },
        {
          name: "售罄",
          type: 0,
        },
      ],
      isTc: false,
      TcdATA: {},
      cascaderValue: "",
      cascaderOptions: [],
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
      },
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "BasissCopeData",
      "FinilsCategoryList",
      "allJurisdiction",'detailsSeach'
    ]),
    ...merchantCenterList.mapState(["businessList"]),
  },
  created() {
    let router=this.$router.history.current.path.substr(1)
    if(this.detailsSeach[router]){
      this.ycApi=this.detailsSeach[router]
      this.cascaderValue=this.detailsSeach[router].cascaderValue
    }
    this.getList();
    this.getBusinessList();
    this.cgOptions();
  },
  methods: {
    //
    ...merchantCenterList.mapActions(["getBusinessList"]),
    ...mapActions(["getgoodsInfolist", "postcategorycommission"]),
    ...commonIndex.mapMutations(['changeSeach']),
    // 远程收缩
    remoteMethod(query) {
      this.getBusinessList({ name: query });
    },
    getList1() {
      this.ycApi.page = 1;
      this.getList();
    },
    // 获取列表
    async getList(e) {
      if (e) {
        this.ycApi.page = 1;
      }
      let data = await this.getgoodsInfolist(this.ycApi);
      //console.log(data);
      let router=this.$router.history.current.path.substr(1)
        let save={...this.ycApi}
        save.cascaderValue=this.cascaderValue
        save.router=router
        this.changeSeach(save)
      if (data) {
        data.data.data.forEach((v) => {
          let categoryArr = [];
          v.category_string.forEach((v1) => {
            // console.log(v1)
            let category = "";
            if (v1.length > 0) {
              v1.forEach((v2, i2) => {
                if (i2 == v1.length || i2 == 0) {
                  category = category + v2;
                } else {
                  category = category + ">" + v2;
                }
              });
            }
            categoryArr.push(category);
          });
          v.category_string = categoryArr;
        });
        this.YcData = data.data;
      }
    },
    handleCurrentChange(e) {
      this.ycApi.page = e;
      this.getList();
    },
    //重置搜索条件
    reset() {
      this.ycApi = {
        business_scope_id: "",
        for_sale: "",
        category_id: "",
        keyword: "",
        page: 1,
        whether_shared: 1,
      };
      this.cascaderValue = [];
      ++this.refKey;
      this.getList();
    },

    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    toMessage(row) {
      this.$router.push({
        path: "/cloudGoodMessage",
        query: { id: row.id, push: 1 },
      });
    },
    // 调整次级联动结构格式
    cgOptions() {
      setTimeout((v) => {
        this.cascaderOptions = categoryList(this.FinilsCategoryList);
      }, 1000);
    },
    cascaderhandleChange(value) {
      this.ycApi.category_id = value[value.length - 1];
    },
    // 调整频台
    toAdjust(row) {
      //console.log(row);
      this.TcdATA = {
        id: row.id,
        commission: row.platform_shared_brokerage_ratio,
      };

      this.isTc = true;
    },
    async Adjust() {
      let data = await this.postcategorycommission(this.TcdATA);
      if (data.code == 200) {
        this.getList();
        this.isTc = false;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.goods-message {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img-box {
    width: 50px;
    height: 50px;
    background: rebeccapurple;
  }
  .span-box {
    margin-left: 10px;
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    div {
      width: 100%;
      text-align: left;
    }
  }
}
.span-box-1 {
  width: 100%;
  div {
    text-align: left;
  }
}
::v-deep .el-cascader{
  width: 100%;
}
::v-deep .el-select{
  width: 100%;
}
</style>
